<template>
  <div class="corecustomize">
    <HeadMobile carouseType="CUSTOMIZED" />
    <div class="corecustomize-titles">
      <p class="corecustomize-title">{{ $store.state.staticName.cus_title }}</p>
      <p class="corecustomize-default">
        {{ $store.state.staticName.cus_subhead }}
      </p>
    </div>
    <div class="corecustomize-box">
      <div
        class="corecustomize-box-item"
        @click="goTitaniumProducts(item.id)"
        v-for="(item, i) in customizedList"
        :key="'c' + i"
      >
        <div class="corecustomize-box-item-title">{{ item.name }}</div>
        <img class="img-bg" :src="item.abbreviation[0]" />
        <!--        <el-image-->
        <!--            class="img-bg"-->
        <!--            :src="item.abbreviation[0]"-->
        <!--            :preview-src-list="item.abbreviation"-->
        <!--        ></el-image>-->
      </div>
    </div>
    <div
      class="corecustomize-box-info"
      v-for="(item, i) in cPYInfoList"
      :key="'item' + i"
    >
      <!-- <h1 class="fu-h1">{{ item.title }}</h1>
      <div class="xian"></div> -->
      <h1 class="fu-h2" v-if="item.subhead">{{ item.subhead }}</h1>
      <p>
        {{ item.content }}
      </p>
      <div class="img-box">
        <div
          class="img-item"
          v-for="(zitem, i) in item.abbreviation"
          :key="'zitem' + i"
        >
          <el-image
            class="img-item"
            :src="zitem"
            :preview-src-list="[zitem]"
          ></el-image>
        </div>
      </div>
    </div>
    <!--    <div class="corecustomize-box-info">-->
    <!--      <h1 class="fu-h1">我的团队</h1>-->
    <!--      <div class="xian"></div>-->
    <!--      <h1 class="fu-h2">技术总监：王枫</h1>-->
    <!--      <p>      6年钛及钛合金加工行业从业经验，专注于新型钛合金的开发研究，致力于从新型钛合金的熔炼、锻造、轧制到表面处理、机械加工全流程制作，在2012已经开发出Ti-6Al-5Zr高温钛合金钛板应用于涡轮发动机叶片，在2015年Ti-3.5Nb-3.0Fe-3.0V-1.5Mo-1.5Zr高硬度钛合金应用于潜水刀具和户外刀具，其洛氏硬度可达到HRC55，抗拉强度可达到1700Mpa。2018年进入午跃科技一直致力于义肢用钛合金的研发和精密加工。-->
    <!--      </p>-->
    <!--      <div class="img-box">-->
    <!--        <div class="img-item">-->

    <!--        </div>-->
    <!--        <div  class="img-item">-->

    <!--        </div>-->
    <!--        <div  class="img-item">-->

    <!--        </div>-->
    <!--        <div  class="img-item">-->

    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <FootMobile />
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from "vuex";
export default {
  name: "Corecustomiza",
  components: {
    HeadMobile,
    FootMobile,
  },
  data() {
    return {
      customizedList: [],
      cPYInfoList: [],
    };
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        this.getCUSCustomizedAll();
        this.getCPYInfoAll();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  created() {
    this.getCUSCustomizedAll();
    this.getCPYInfoAll();
  },
  methods: {
    getCUSCustomizedAll() {
      this.$axios({
        url: "/queryCUS_CustomizedAll",
        method: "get",
        params: {
          type: this.languageType,
        },
      })
        .then((res) => {
          this.customizedList = res.data.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCPYInfoAll() {
      this.$axios({
        url: "/queryCPY_InfoByCus",
        method: "get",
        params: {
          id: 0,
          type: this.languageType,
        },
      })
        .then((res) => {
          this.cPYInfoList = res.data.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goTitaniumProducts(id) {
      this.$router.push({ path: `/TitaniumProductsMobile/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.corecustomize-box-item {
  cursor: pointer;
}
.corecustomize {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .corecustomize-titles {
    margin-top: 14rem;
    margin-bottom: 16rem;
    color: #4d4d4d;
    text-align: center;

    .corecustomize-title {
      font-size: 14px;
    }

    .corecustomize-default {
      font-size: 12px;
      color: #333333;
    }
  }
  .corecustomize-box {
    width: 375px;
    overflow-x: scroll;
    margin: 0 auto;
    display: grid;
    place-items: center;
    grid-template-columns: 181px 181px 181px;
    grid-template-rows: 154px;
    //grid-row-gap: 38px;
    .corecustomize-box-item {
      position: relative;
      width: 174px;
      height: 152px;
      background: #d1d1d1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .corecustomize-box-item-title {
        position: absolute;
        width: 105px;
        height: 20px;
        //opacity: 0.4;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 49px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
      }
      .img-bg {
        width: 174px;
        height: 151px;
      }
    }
  }
  .corecustomize-box-info {
    width: 356px;
    margin: 18px auto 0;

    .fu-h1 {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #333333;
    }
    .fu-h2 {
      font-size: 12px;
      font-weight: 500;
    }
    .xian {
      margin-top: 3px;
      margin-bottom: 3px;
      width: 356px;
      height: 1px;
      background-color: #666666;
      //border: 1px solid #666666;
    }
    p {
      text-indent: 2em;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #666666;
      margin-bottom: 17px;
    }
    .img-box {
      width: 100%;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      .img-item:nth-child(4n-3) {
        margin-left: 0px;
      }
      .img-item {
        margin-left: 126px;
        width: 111px;
        height: 84px;
        margin-bottom: 60px;
        background: #d1d1d1;

        img {
          width: 320px;
          height: 260px;
        }
      }
    }
  }
}
</style>
